import React, { useState } from "react"
import PropTypes, { nominalTypeHack } from "prop-types"
import { useStaticQuery, graphql } from "gatsby"
import Header from "./header"
import Footer from "./footer"
import MainContent from "./main-content"
//styled components
import { Close, ProjectLayout } from "../styles/project-styles"
import { GridLayout, MarginBottom } from "../styles/global-styles"
import { createGlobalStyle, ThemeProvider } from "styled-components"
import { normalize } from "styled-normalize"
import { AnimatePresence } from "framer-motion"
const GlobalStyle = createGlobalStyle`
  ${normalize};

  * {
    text-decoration: none;
    /* cursor: none; */
  }

  html {
    box-sizing: border-box;
    -webkit-font-smoothing: antialiased;
    background: ${props => props.theme.backgroundColor};
    font-size: 16px;
    height: 100%;
  }

  body {
    font-family: ${props => props.theme.bodyFont}, sans-serif;
    background: ${props => props.theme.backgroundColor}; //TODO: make variable
    overscroll-behavior: none;
    overflow-x: hidden;
    margin: 0;
  }
`

const Layout = ({ children, project }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  const theme = {
    backgroundColor: "#FFFDF3",
    black: "#363636",
    green: "#669B6C",
    titleFont: "Playfair Display",
    bodyFont: "Open Sans",
  }

  const projectLayout = (
    <ProjectLayout>
      {children}
      <Footer />
      <MarginBottom amount="2rem" />
    </ProjectLayout>
  )
  const mainLayout = (
    <GridLayout>
      <Header />
      <MainContent>{children}</MainContent>
      <Footer />
    </GridLayout>
  )

  return (
    <ThemeProvider theme={theme}>
      <GlobalStyle />
      {project ? projectLayout : mainLayout}
    </ThemeProvider>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
