import React from "react"

export const Linkedin = () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
    <path d="M22.286 0H1.709A1.722 1.722 0 000 1.73v20.54A1.722 1.722 0 001.709 24h20.577A1.726 1.726 0 0024 22.27V1.73A1.726 1.726 0 0022.286 0zM7.254 20.571H3.7V9.118h3.559v11.453zM5.475 7.55a2.063 2.063 0 112.063-2.059A2.063 2.063 0 015.475 7.55zm15.112 13.021H17.03V15c0-1.329-.027-3.038-1.848-3.038-1.854 0-2.138 1.446-2.138 2.941v5.668H9.488V9.118H12.9v1.564h.048a3.747 3.747 0 013.37-1.848c3.6 0 4.27 2.373 4.27 5.459z" />
  </svg>
)

export const Github = () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
    <path d="M21.429 0H2.571A2.572 2.572 0 000 2.571v18.858A2.572 2.572 0 002.571 24h18.858A2.572 2.572 0 0024 21.429V2.571A2.572 2.572 0 0021.429 0zm-6.574 20.555c-.45.08-.616-.2-.616-.429 0-.289.011-1.768.011-2.962a2.087 2.087 0 00-.605-1.645c1.982-.22 4.071-.493 4.071-3.916a2.75 2.75 0 00-.916-2.089 3.35 3.35 0 00-.091-2.411c-.745-.23-2.448.959-2.448.959a8.428 8.428 0 00-4.457 0S8.1 6.873 7.355 7.104a3.325 3.325 0 00-.091 2.411 2.691 2.691 0 00-.836 2.089c0 3.407 2 3.7 3.98 3.916a1.942 1.942 0 00-.568 1.195 1.88 1.88 0 01-2.588-.745 1.866 1.866 0 00-1.365-.92c-.868-.011-.059.546-.059.546.579.268.986 1.3.986 1.3.52 1.592 3.006 1.054 3.006 1.054 0 .745.011 1.955.011 2.175s-.161.509-.616.429A8.888 8.888 0 013.2 12.075a8.5 8.5 0 018.679-8.652 8.691 8.691 0 018.9 8.652 8.806 8.806 0 01-5.924 8.48zM9.6 17.282c-.1.021-.2-.021-.209-.091s.059-.15.161-.171.2.032.209.1-.054.141-.161.162zm-.509-.048c0 .07-.08.129-.187.129s-.2-.048-.2-.129.08-.129.188-.129.199.045.199.129zm-.734-.059c-.021.07-.129.1-.22.07s-.171-.1-.15-.171.129-.1.22-.08c.107.031.177.111.15.181zm-.657-.289c-.048.059-.15.048-.23-.032s-.1-.171-.048-.22.15-.048.23.032.094.177.048.22zm-.488-.487c-.048.032-.139 0-.2-.08s-.059-.171 0-.209.15-.011.2.07a.157.157 0 01-.001.218zm-.348-.52c-.048.048-.129.021-.187-.032-.059-.07-.07-.15-.021-.187s.129-.021.188.032c.056.069.067.149.018.187zm-.359-.4a.1.1 0 01-.15.021c-.07-.032-.1-.091-.08-.139a.126.126 0 01.15-.021c.068.04.1.099.075.142z" />
  </svg>
)

export const ImgPlacement = () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 58.805 46.935">
    <g data-name="Group 77191">
      <path fill="#fff" d="M.1 1h56v44H.1z" data-name="Rectangle 144921" />
      <path
        d="M56.759 0H2.047A2.047 2.047 0 000 2.047v42.841a2.047 2.047 0 002.047 2.047h54.712a2.047 2.047 0 002.047-2.047V2.047A2.047 2.047 0 0056.759 0zm-2.047 33.564L39.226 25.1a2.047 2.047 0 00-2.388.273l-8.05 7.572-11.051-7.704a2.047 2.047 0 00-2.524.136L4.093 35V4.1h50.619z"
        data-name="Compound Path"
      />
      <path d="M26.947 23.467a7.095 7.095 0 10-7.095-7.094 7.095 7.095 0 007.095 7.095z" />
    </g>
  </svg>
)
