import styled, { css } from "styled-components"
import { device } from "./device"
import { motion } from "framer-motion"
export const StyledHeader = styled(motion.header)`
  grid-row: header-start/main-content;
`
export const Asterisk = styled.span`
  font-family: ${props => props.theme.titleFont};
  font-size: 3rem;
`

export const Menu = styled.ul`
  padding: 0;
  margin: 0;
  list-style: none;
  display: flex;
  justify-content: space-between;
  width: 100%;
`

export const MenuItem = styled.li`
  margin-right: 1rem;
  &:last-child {
    margin-right: 0;
  }

  a {
    position: relative;
    font-family: ${props => props.theme.bodyFont};
    font-size: 0.75rem;
    color: ${props => props.theme.black};
    text-transform: uppercase;
    font-weight: 200;

    /* &::after {
      content: "";
      position: absolute;
      top: calc(100% + 0.3rem);
      left: 0;
      width: 0%;
      height: 3px;
      background-color: ${props => props.theme.green};
      transition: 0.3s ease;

      ${props =>
        props.active &&
        css`
          width: 100%;
        `}
    } */
  }

  /* &:hover {
    a::after {
      width: 100%;
    }
  } */

  @media ${device.tablet} {
    margin-right: 1rem;
    &:last-child {
      margin-right: 0;
    }
  }
`
export const VerticalBar = styled.span`
  width: 1px;
  height: 1.5rem;
  background: ${props => props.theme.black};
  display: inline-block;
  position: relative;
  bottom: 0;
  margin: auto 0;
`

export const Logo = styled.div`
  position: relative;
/* 
  a{
    height: 100%;
    display: block;
  } */
  /* a {
    position: relative;
    font-family: ${props => props.theme.titleFont};
    font-size: 1rem;
    font-weight: 500;
    color: ${props => props.theme.black};
  }

  @media ${device.tablet} {
    a {
      font-size: 1rem;
    }
  } */
`

export const FirstLogoLetter = styled.span`
  position: relative;
  top: -0.4rem;
  left: -8px;
`
export const SecondLogoLetter = styled.span`
  position: relative;
  top: 0.3rem;
  left: 6px;
`
