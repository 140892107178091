import styled, { css } from "styled-components"
import { motion } from "framer-motion"

export const Container = styled(motion.div)`
  flex-grow: 1;
  margin: 0 auto;
  padding: 0 32px;
  position: relative;
  width: auto;
  height: 100%;

  @media (min-width: 1024px) {
    width: 960px;
  }
  @media (min-width: 1216px) {
    width: 1152px;
  }
  @media (min-width: 1408px) {
    width: 1244px;
  }

  ${props =>
    props.fluid &&
    css`
      padding: 0;
      margin: 0;
      width: 100% !important;
    `}
`
export const GridLayout = styled.div`
  display: grid;
  grid-template-rows: [top-margin] 2rem [header-start] max-content [main-content] 1fr [footer-start] max-content [bottom-margin] 2rem;
  min-height: 100vh;
  /* overflow-y: hidden; */
`

export const Flex = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  ${props =>
    props.spaceBetween &&
    css`
      justify-content: space-between;
    `};
  ${props =>
    props.flexEnd &&
    css`
      justify-content: flex-end;
    `};
  ${props =>
    props.alignTop &&
    css`
      align-items: flex-start;
    `};
  ${props =>
    props.noHeight &&
    css`
      height: 0;
    `};
`
export const Cursor = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 32px;
  height: 32px;
  background: ${props => props.theme.red};
  border-radius: 100%;
  transform: translate(-50%, -50%);
  transition: all 0.1s ease-in-ease-in-out;
  transition-property: width, height, border;
  will-change: width, height, transform, border;
  pointer-events: none;
  z-index: 999;

  &.pointer {
    border: 4px solid ${props => props.theme.text} !important;
  }

  &.hovered {
    background: transparent !important;
    width: 56px;
    height: 56px;
    border: 4px solid ${props => props.theme.red};
  }

  &.nav-open {
    background: ${props => props.theme.text};
  }
`

export const ScrollWrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  /* height: 100%; */
`

export const Scroll = styled.div`
  /* height: 100%; */
`
export const customEase = [0.6, 0.5, -0.01, 0.9]
export const easeInOut = (duration = 0.6) => ({
  duration: duration,
  ease: [0.43, 0.13, 0.23, 0.96],
})

export const MarginBottom = styled.div`
  margin: 0;
  padding: 0;
  height: ${props => props.amount || "2rem"};
`
