import styled from "styled-components"

export const StyledFooter = styled.footer`
  grid-row: footer-start/bottom-margin;
  align-self: center;
`

export const SocialIcons = styled.div`
  display: flex;
  justify-content: space-between;

  a {
    width: 1.5rem;
    height: 1.5rem;
    margin-right: 1rem;

    &:last-child {
      margin-right: 0;
    }

    svg {
      fill: ${props => props.theme.black};
      width: 100%;
      height: 100%;
    }
  }
`

export const Email = styled.span`
  /* writing-mode: vertical-rl; */
  position: absolute;
  left: 0;
  bottom: 0;
  font-size: 0.75rem;
  /* align-self: start; */
`
