import { Link } from "gatsby"
import React, { useState } from "react"
import {
  useGlobalDispatchContext,
  useGlobalStateContext,
} from "../context/globalContext"
import { Container, customEase, Flex } from "../styles/global-styles"
import {
  StyledHeader,
  Logo,
  Menu,
  MenuItem,
  VerticalBar,
  FirstLogoLetter,
  SecondLogoLetter,
  Asterisk,
} from "../styles/header-styles"

const Header = () => {
  const { firstLoad } = useGlobalStateContext() || true
  const dispatch = useGlobalDispatchContext()
  const updateFirstLoad = () => dispatch({ type: "FIRST_LOAD", payload: false })

  return (
    <StyledHeader
      animate={{ y: 0, opacity: 1 }}
      initial={firstLoad ? { y: -72, opacity: 0 } : false}
      transition={{ duration: 1, ease: customEase }}
      onAnimationComplete={updateFirstLoad}
    >
      <Container>
        <Flex spaceBetween>
          <Logo>
            <Link to="/">
              {/* <Flex spaceBetween>
                <FirstLogoLetter>A</FirstLogoLetter>
                <VerticalBar />
                <SecondLogoLetter>G</SecondLogoLetter>
              </Flex> */}
              <Asterisk>*</Asterisk>
            </Link>
          </Logo>
          <nav>
            <Menu>
              <MenuItem active>
                <Link to="/">Home</Link>
              </MenuItem>
              <MenuItem>
                <Link to="/work/">Work</Link>
              </MenuItem>
              <MenuItem>
                <Link to="/about">About</Link>
              </MenuItem>
            </Menu>
          </nav>
        </Flex>
      </Container>
    </StyledHeader>
  )
}

export default Header
