import React from "react"
import { Github, Linkedin } from "../assets/svg/social-icons"
import { Flex, Container } from "../styles/global-styles"
import { StyledFooter, SocialIcons, Email } from "../styles/footer-styles"
const Footer = ({ style }) => {
  return (
    <StyledFooter style={style}>
      <Container>
        <Flex flexEnd>
          <Email>&copy; 2020 Alvin Ghouas</Email>
          <SocialIcons>
            <a href="https://github.com/alvinalexander">
              <Github />
            </a>
            <a href="https://www.linkedin.com/in/alvinghouas/">
              <Linkedin />
            </a>
          </SocialIcons>
        </Flex>
      </Container>
    </StyledFooter>
  )
}

export default Footer
