import { Link } from "gatsby"
import styled, { css } from "styled-components"
import { device } from "./device"
import Img from "gatsby-image"

export const ProjectLayout = styled.div`
  min-height: 100vh svg {
    width: 100%;
    height: 100%;
  }
  position: relative;
  margin-bottom: 2rem;
`

export const SpreadOuter = styled.div`
  margin-bottom: 3rem;
  @media ${device.tablet} {
    margin-bottom: 18rem;
  }
`

export const SpreadInner = styled.div`
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  /* grid-template-rows: repeat(6, 1fr); */
  width: 100%;
  height: 100%;
  column-gap: 0.5rem;
  row-gap: 0.5rem;
  position: relative;
`

export const SpreadText = styled.div`
  grid-column-start: 1;
  grid-column-end: span 13;
  display: grid;
  grid-template-rows: repeat(
    3,
    ${props => (props.even ? "1fr" : "max-content")}
  );

  @media ${device.tablet} {
    grid-column-start: ${props => (props.colStart ? props.colStart : 1)};
    grid-column-end: ${props => (props.colEnd ? props.colEnd : "span 5")};
    grid-row-start: ${props => (props.rowStart ? props.rowStart : "auto")};
    grid-row-end: ${props => (props.rowEnd ? props.rowEnd : "auto")};
  }
`

export const TextSectionWrapper = styled.div`
  position: relative;
  margin-bottom: 1rem;
  align-self: ${props => (props.alignSelf ? props.alignSelf : "auto")};

  @media ${device.tablet} {
    grid-column-start: ${props => (props.colStart ? props.colStart : "auto")};
    grid-column-end: ${props => (props.colEnd ? props.colEnd : "auto")};
    grid-row-start: ${props => (props.rowStart ? props.rowStart : "auto")};
    grid-row-end: ${props => (props.rowEnd ? props.rowEnd : "auto")};
  }
`
export const SpreadImageGrid = styled.div`
  grid-column: 1 / span 13;
  display: grid;
  grid-template-columns: repeat(7, minmax(0, 1fr));

  gap: 0.5rem;

  @media ${device.tablet} {
    max-height: 41rem;
    grid-template-rows: repeat(6, minmax(0, 1fr));
    grid-column-start: ${props => (props.colStart ? props.colStart : 6)};
    grid-column-end: ${props => (props.colEnd ? props.colEnd : "span 7")};
    grid-row-start: ${props => (props.rowStart ? props.rowStart : "auto")};
    grid-row-end: ${props => (props.rowEnd ? props.rowEnd : "auto")};
  }
`

export const Hero = styled.div`
  grid-column: 1 / span 12;
  grid-row: 1 / span 6;
  background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
    url(${props => props.imgUrl});

  background-size: cover;

  &::before {
    content: "";
    display: block;
    /* content-box makes sure padding adds to declared height */
    box-sizing: content-box;
    width: 100%;
    height: 0;
    /* Vertical padding is based on parent width */
    /* So we want 1225/1920, converted to % as our vertical padding */
    /* padding: 0 0 63.8%; */
    padding-bottom: calc(100% / ${props => props.aspectRatio});
  }

  @media ${device.tablet} {
    background: url(${props => props.imgUrl});
    background-size: cover;
  }

  img {
    width: 100%;
    object-fit: cover;
  }
`

export const FullWidthImage = styled.div`
  grid-column: 1 / span 12;
  grid-row: 1 / span 6;
  img {
    width: 100%;
    object-fit: cover;
  }
`

export const Video = styled.div`
  position: relative;
  grid-column-end: span 12;
  grid-row-end: span 6;

  &::before {
    content: "";
    display: block;
    /* content-box makes sure padding adds to declared height */
    box-sizing: content-box;
    width: 100%;
    height: 0;
    /* Vertical padding is based on parent width */
    /* So we want 9/16, converted to % as our vertical padding */
    padding: 0 0 56.25%;
  }
`

export const VideoFrame = styled.iframe`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: none;
`

export const Close = styled(Link)`
  width: 1rem;
  height: 1rem;
  position: fixed;
  top: 1rem;
  right: 0.5rem;
  z-index: 999;
  border-radius: 50%;
  border: 1px solid white;
  mix-blend-mode: difference;
  margin: 0;

  &::after,
  &::before {
    content: "";
    transform-origin: center center;
    background: white;
    width: 1px;
    height: 0.5rem;
    display: block;
    position: absolute;
    top: 50%;
    /* margin: 0 auto; */
    right: 50%;

    /* transform: translateY(-0.25rem); */
  }

  &::after {
    transform: translateY(-50%) rotate(45deg);
  }

  &::before {
    transform: translateY(-50%) rotate(-45deg);
  }

  @media ${device.tablet} {
    width: 2rem;
    height: 2rem;
    right: 2rem;
    border: 2px solid white;

    &::after,
    &::before {
      height: 1rem;
      width: 2px;
    }
  }
`

export const ImageCaption = styled.span`
  font-size: 0.75rem;
`

export const Image = styled.div`
  position: relative;
  grid-column-end: span 12;
  margin-bottom: 2rem;

  @media ${device.tablet} {
    margin-bottom: ${props => (props.caption ? "1.5rem" : "0")};
    grid-column-start: ${props => (props.colStart ? props.colStart : "auto")};
    grid-column-end: ${props => (props.colEnd ? props.colEnd : "auto")};
    grid-row-start: ${props => (props.rowStart ? props.rowStart : "auto")};
    grid-row-end: ${props => (props.rowEnd ? props.rowEnd : "auto")};
    align-self: ${props => (props.alignSelf ? props.alignSelf : "auto")};
  }

  .gatsby-image-wrapper {
    width: 100%;
    height: 100%;
  }
  /*
  img {
    object-fit: cover;
  } */
`

//rename spread item
export const SpreadContent = styled.div`
  position: relative;
  /* grid-column-end: span 12; */
  margin-bottom: 1rem;
  align-self: ${props => (props.alignSelf ? props.alignSelf : "auto")};

  @media ${device.tablet} {
    grid-column-start: ${props => (props.colStart ? props.colStart : "auto")};
    grid-column-end: ${props => (props.colEnd ? props.colEnd : "auto")};
    grid-row-start: ${props => (props.rowStart ? props.rowStart : "auto")};
    grid-row-end: ${props => (props.rowEnd ? props.rowEnd : "auto")};
  }
`

export const SectionTitle = styled.h1`
  font-family: ${props => props.theme.titleFont};
  font-size: 1.5rem;
  line-height: 1;
  margin-top: 0;
  margin-bottom: 1rem;
`

export const BodyText = styled.p`
  font-family: ${props => props.theme.bodyFont};
  font-size: 1rem;
  line-height: 1.5;
  margin: 0;
  text-align: justify;
`

export const Detail = styled.div`
  display: flex;
  justify-content: space-between;
  align-content: center;
  width: 100%;
  font-family: ${props => props.theme.bodyFont};
  /* line-height: 2rem; */
  margin: 1rem 0;
`

export const DetailKey = styled.span`
  font-size: 1rem;
  font-weight: 600;
`

export const DetailValue = styled.span`
  flex-basis: 40%;
  text-align: left;
  /* line-height: 1.3; */
`

export const SpreadTitleWrapper = styled.div`
  position: relative;
  margin-top: 0;
  margin-bottom: 1rem;
`
export const Title = styled.h1`
  font-family: ${props => props.theme.titleFont};
  font-size: 2rem;
  line-height: 1;
  margin: 0;
`

export const ProcessStep = styled.div`
  /* display: flex;
  align-items: flex-start; */
  position: relative;
  margin-bottom: 1rem;
`

export const StepIcon = styled.div`
  display: none;

  svg {
    width: 100%;
    height: 100%;
  }

  /* @media ${device.laptop} {
    display: block;
    position: absolute;
    top: 0.2rem;
    left: -2.5rem;
    width: 1.5rem;
    height: 1.5rem;
  } */
`

export const StepText = styled.div``

export const StepTitle = styled.h3`
  margin-top: 0;
  margin-bottom: 0.5rem;
  font-size: 1rem;
  font-style: italic;
  font-weight: 400;
`

export const StepBody = styled.p`
  margin: 0;
  font-size: 1rem;
  line-height: 1.5;
  text-align: justify;
`

export const CaptionLeftInner = styled.p`
  margin: 0;
  text-align: right;
  font-size: 0.75rem;
`

export const ProjectTitle = styled.div`
  grid-column: 2 / span 8;
  grid-row: 4 / span 2;
  margin-top: 2rem;
  color: white;

  @media ${device.tablet} {
    grid-column: 9 / span 3;
    grid-row: 1 / span 2;
    margin-top: 4rem;
  }
`
export const Headline = styled.h1`
  margin: 0;
  font-family: ${props => props.theme.titleFont};
  font-weight: 600;
  font-size: 3.5rem;

  @media ${device.tablet} {
    font-size: 5vw;
  }
`
export const Tagline = styled.span`
  font-size: 1rem;
  @media ${device.tablet} {
    font-size: 1.2vw;
  }
`

export const ImagePlacementIcon = styled.span`
  position: absolute;
  top: 1rem;
  left: 1rem;
  z-index: 99;

  width: 59px;
  height: 47px;

  svg {
    width: 100%;
    height: 100%;
  }
`
//Animation

export const projectWrapper = {
  close: { opacity: 0, transition: { duration: 0.1 } },
  open: {
    opacity: 1,
  },
}
